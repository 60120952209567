<template>
    <table class="table table-sm table-bordered mb-0" style="table-layout: fixed;font-size:80%" v-if="!integrated && dsItemsProperties.data && (alwaysShowGrid || (dsItemsProperties.data.length > 0 && !readOnly))">
        <tbody>
            <template v-for="(item,index) in dsItemsProperties.data">
                <tr v-if="showHeader(index)" class="mb-1">
                    <td colspan="2" class="fw-bold">{{item.GroupByHeader}}</td>
                </tr>
                <tr>
                    <td style="width:40%">
                        <div class="d-flex align-items-center">
                            <div style="width:1rem; height:1.5rem;" class="me-1">
                                <i v-if="item._state.isSaving" class="spinner-border spinner-border-sm mt-1 me-1" :title='$t("Saving...")' ></i>
                                <button v-else class="btn btn-link p-0" @click="dsItemsProperties.save(item.index)" :hidden="!item._state.hasChanges">
                                    <i class="bi bi-check-lg icon-bold text-primary p-0" :title='$t("Save")'></i>
                                </button>
                            </div>
                            <div>{{item.Caption}}</div> 
                        </div>
                        <template v-if="!props.objectsMode && item && item.RequiredAtStep !== null && (item.RequiredAtStep <= props.step)">*</template>
                        <template v-if="props.objectsMode && item && item.Required">*</template>
                        <i class="bi bi-info-circle ms-1" v-if="item.AdditionalInfo" :title="item.AdditionalInfo"></i>
                        <a v-if="item.Url" :href="item.Url"><i class="bi bi-link-45deg"></i></a>
                    </td>
                    <td style="width:60%">
                        <div class="d-flex align-items-center">
                            <OEditorItem :item="item" @blur="save()" :valueSetter="propertySetter" :valueGetter="propertyGetter" style="width:100%" class="border-0 w-100" :disabled="item.LockAfterStep !== null && props.step > item.LockAfterStep" />
                            <div style="width:1rem; height:1.5rem;" class="mx-1">
                                <button class="btn btn-link p-0" v-if="item._state.hasChanges && !item._state.isSaving" @mousedown="resetValue(item)" title="Cancel pending changes">
                                    <i class="bi bi-arrow-counterclockwise btn btn-link p-0"></i>
                                </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
        </tbody>
    </table> 
    <template v-if="integrated && dsItemsProperties.data && (alwaysShowGrid || (dsItemsProperties.data.length > 0 && !readOnly))" v-for="(obj, key) in filteredProps">
        <div>
            <b class="p-2 w-100 d-inline-block bg-light border-bottom" 
                v-if="key != 'null'" 
                @click="setCollapsed($event)" 
                data-bs-toggle="collapse" 
                :data-bs-target="`#collapse-${sanitizeForId(key)}`" 
                style="cursor: pointer;"
            >
                <i class="bi bi-dash-square me-2"></i>
                <span>{{key}}</span>
            </b>
            <div class="collapse show" :id="`collapse-${sanitizeForId(key)}`">
                <template v-for="item in obj.items">
                    <div class="d-flex justify-content-between border-bottom bg-white">
                        <span style="width:40%" class="ps-2 border-end">
                            {{item.Caption}} 
                            <template v-if="!props.objectsMode && item && item.RequiredAtStep !== null && (item.RequiredAtStep <= props.step)">*</template>
                            <template v-if="props.objectsMode && item && item.Required">*</template>
                            <i class="bi bi-info-circle ms-1" v-if="item.AdditionalInfo" :title="item.AdditionalInfo"></i>
                            <a v-if="item.Url" :href="item.Url"><i class="bi bi-link-45deg"></i></a>
                        </span>
                        <span style="width:60%">
                            <OEditorItem :item="item" @blur="save()" :valueSetter="propertySetter" :disabled="item.LockAfterStep !== null && props.step > item.LockAfterStep" :valueGetter="propertyGetter" style="width:100%" class="border-0 w-100" />
                        </span>
                    </div>
                </template> 
            </div>
        </div>
    </template>
    <small class="ms-1" v-if="hasRequiredFields() && dsItemsProperties.state._isLoaded && !readOnly" >{{$t("* Required")}}</small>
    <div v-if="objectsMode" class="mt-2">
        <ODataLookup :dataObject="propertyLookupDataObject"
            :bind="sel=>{ addNewObjProperty(sel) }">
            <template #target="scope" @click="scope.open">
                <button :ref="scope.target" type="button" class="btn btn-link p-0 mt-2 text-decoration-none">
                    <i class="bi bi-plus-circle p-2">&nbsp;{{$t('Add Property')}}</i>
                </button>
            </template>
            <OColumn field="Name" width="500" :headerName="$t('Property Name')"/>
        </ODataLookup>
    </div>
    <template v-if="dsItemsProperties.data.length > 0 && readOnly" v-for="(property,index) in dsItemsProperties.data" :key="property.ID">
        <h6 v-if="showHeaderReadOnly(index)" class="mb-0 mt-2">{{property.GroupByHeader}}</h6>
        <div class="" v-if="readOnly && (property.ShowAlways || property.Value != null || property.IntValue != null || property.DateValue != null || property.DateTimeValue != null) ">            
            <span class="me-2  " style="font-weight:500" :title="property.Caption">{{property.Caption}}:</span>
            <span v-if="property.DataType == 'string'" :title="property.Value" style="overflow-wrap: break-word;"> {{property.Value}}</span>
            <span v-else-if="property.DataType == 'number'" :title="property.IntValue ?? property.Value"> {{property.IntValue ?? property.Value}}</span>
            <span v-else-if="property.DataType == 'bool'" > 
                <i class="bi bi-check-square" v-if="property.IntValue == 1"></i>  
                <i class="bi bi-square" v-else></i>  
            </span>
            <span v-else-if="property.DataType == 'date'"> {{utils.formatDate(property.DateValue,'Short Date')}}</span>
            <span v-else-if="property.DataType == 'datetime'"> {{utils.formatDate(property.DateTimeValue,'General Date Short Time')}}</span>
        </div>
    </template>
</template>

<script setup>
    import { computed, ref, onUnmounted, watch, inject } from 'vue';
    import { OEditorItem } from 'o365-inputeditors';
    import { InjectionKeys } from 'o365-utils';
    import { getOrCreateDataObject } from 'o365-dataobject'; 
    import { utils } from 'o365-utils';   

    const props = defineProps({
        step: Number,
        readOnly: {
            type: Boolean,
            default: false,
        },
        itemId: Number,
        viewName:String,
        integrated: Boolean,
        parentFieldName:String,
        uniqueTable:String,
        alwaysShowGrid: {
            type: Boolean,
            default: false,
        },
        objectsMode: {
            type: Boolean,
            default: false,
        },
        propertyLookupDataObject: {
            type: Object,
            default: null
        },
        isStandardConfig:{
            type: Boolean,
            default:false
        },
        dataObject: Object,
        enableDelete: Boolean
    });

    const sharedObj = inject(InjectionKeys.propertiesGridKey, null);

    const propsFilter = ref(null);
    const withValues = ref(false);

    watch(() => sharedObj?.value.propFilter, (newVal) => {
        propsFilter.value = newVal; 
    });

    watch(() => sharedObj?.value.withValues, (newVal) => {
        withValues.value = newVal;
    });   

    const filteredProps = computed(() => {
        var data = [];
        if(!propsFilter.value && !withValues.value){
            data = dsItemsProperties.data;
        }
        data = dsItemsProperties.data.filter(x => {
            var foundInSearch = !propsFilter.value || x.Caption.toLowerCase().includes(propsFilter.value?.toLowerCase());
            var hasValue = !withValues.value || x.Value != null || x.DateValue != null || x.DateTimeValue != null  || x.IntValue != null;
            if(foundInSearch && (hasValue || !withValues.value)){
                return true;
            } else {
                return false;
            }
        });

        var res = groupBy(data, 'GroupByHeader');

        return res;
    });

    function groupBy(array, key){
        return array.reduce((result, item) => {
            const keyValue = item[key];
            if (!result[keyValue]) {
                result[keyValue] = {};
                result[keyValue].items = [];
            }
            result[keyValue].collapsed = false;
            result[keyValue].items.push(item);
            return result;
        }, {});
    };
    const vViewName = props.viewName ? props.viewName : 'aviw_Workflow_ItemsProperties2';
    const vUniqueTable = props.uniqueTable ? props.uniqueTable : 'atbv_Workflow_ItemsProperties';

    const emit = defineEmits(["propertySaved", "propertyDeleted", "toggledShowAll"]);    

    const vItemPropertyId = vViewName + crypto.randomUUID();

    const dsItemsProperties = props.dataObject ?? getOrCreateDataObject({
        id: vItemPropertyId,
        viewName: vViewName,
        maxRecords: 1000,
        uniqueTable: vUniqueTable,
        allowInsert: true,
        allowDelete: true, 
        allowUpdate: true,
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "ID", type: "number" },
            { name: "PropertyName", type: "string" },
            { name: "Value", type: "string" },
            { name: "DateValue", type: "date" },
            { name: "DateTimeValue", type: "datetime" },
            { name: "IntValue", type: "number" },
            { name: "DataType", type: "string" },
            { name: "RequiredAtStep", type: "number" },
            { name: "Caption", type: "string" },
            { name: "Placeholder", type: "string" },
            { name: "Sequence", type: "number", "sortOrder": 1, "sortDirection": "asc", },
            { name: "GroupByHeader", type: "string", "sortOrder": 2, "sortDirection": "asc"},
            { name: "LookupValues", type: "string" },
            { name: "Required", type: "boolean" },
            { name: "Config", type: "string" },
            { name: "HideUntilStep", type: "number" },
            { name: "ShowAlways", type: "boolean" },
            { name: "AdditionalInfo", type: "string" },
            { name: "Rows", type: "number" },
            { name: "LockAfterStep", type: "number" },
            { name: "Url", type: "string" }
        ]
    });

    const hasProperties = computed(() => dsItemsProperties.data.length > 0);

    defineExpose({
        save,
        reload,
        hasProperties
    });

    function addNewObjProperty(sel){
        if(!props.isStandardConfig)  
            dsItemsProperties.createNew({
                Object_ID: props.itemId, // Table does not accept item_ID, so need to add object_ID 
                PropertyName: sel.Name,
                IsObjectConnection: sel.IsObjectConnection
            })
        else{
            const vIDField = props.parentFieldName?props.parentFieldName:"ObjectID"
            dsItemsProperties.createNew({
                [vIDField]: props.itemId,
                Value: sel.DefaultValue,
                PropertyName: sel.PropertyName,
                Mandatory: sel.Mandatory,
            })
        }

        reload();
    }

    async function reload(){
        if(!props.dataObject){
            dsItemsProperties.recordSource.whereClause = getItemsPropertiesWhereClause();
        }
        await dsItemsProperties.load();
    }

    async function save(){
        await dsItemsProperties.save();
    }

    window.addEventListener("beforeunload", () => {
        dsItemsProperties.save();
    });

    window["dsItemsProperties"] = dsItemsProperties;
    
    reload();

    function getItemsPropertiesWhereClause() {
        const clauses = [];
        clauses.push(`${props.parentFieldName?props.parentFieldName:"Item_ID"} = ${props.itemId}`);
        if (props.step >= 0) {
            clauses.push(`ISNULL(HideUntilStep, 0) <= ${props.step}`);
        }

        return clauses.filter(s => s).join(" AND ");
    }

    onUnmounted(() => {
        beforeSaveCb && beforeSaveCb();
        afterSaveCb && afterSaveCb();
        afterDeleteCb && afterDeleteCb();
    });
    
    const beforeSaveCb = dsItemsProperties.on("BeforeSave",(pRow)=>{
        pRow.values[props.parentFieldName?props.parentFieldName:"Item_ID"] = props.itemId;
    })

    const afterSaveCb = dsItemsProperties.on("AfterSave", () => {
        emit("propertySaved");
    });

    const afterDeleteCb = dsItemsProperties.on("AfterDelete", () => {
        emit("propertyDeleted");
    });

    function setCollapsed(event){
        var target = event.currentTarget;
        var i = target.querySelector("i");
        if(i.classList.contains("bi-dash-square")){
            i.classList.remove("bi-dash-square");
            i.classList.add("bi-plus-square");
        } else {
            i.classList.add("bi-dash-square");
            i.classList.remove("bi-plus-square");
        }
    }    
    
    function propertySetter(pItemEditor, pItem, pValue) {
        switch (pItemEditor.valueType) {
            case 'bool':
                pItem.IntValue = pValue === true ? 1 : null;
                break;
            case 'number':
                pItem.Value = pValue;
                pItem.IntValue = pValue;
                break;
            case 'date':
                pItem.Value = pValue;
                pItem.DateValue = pValue;
                break;
            case 'datetime':
                pItem.Value = pValue;
                pItem.DateTimeValue = pValue;
                break;
            default:
                if(pItemEditor.editor == 'orgunit_lookup' || pItemEditor.editor == 'object_lookup'){
                    pItem.IntValue = pValue;
                    if (pValue == null) { 
                        pItem.Value = null;
                    }
                } else {
                    pItem.Value = pValue;
                }
        }

        if (pItemEditor.displayMember && pItemEditor.displayValue && typeof pValue === 'number') {
            pItem.Value = pItemEditor.displayValue;
            pItem.IntValue = pValue;
        }
    
        if(pItemEditor.isLookup || pItemEditor.editor === 'multiselect'){
            save();
        }
    }

    function propertyGetter(pItemEditor, pItem) {
        if (pItemEditor.valueType === "date") {
            return pItem.DateValue;
        } else if (pItemEditor.valueType === "datetime") {
            return pItem.DateTimeValue;
        }
        else if (pItemEditor.valueType == 'bool' && pItem.IntValue !== null) {
            return pItem.IntValue === 1 ? true : false;
        } else if (pItemEditor.valueType === 'number') {
            return pItem.IntValue ?? pItem.Value;
        } else {
            return pItem.Value;
        }
    }

    function showHeader(index){
        if(index > 0){
            return dsItemsProperties.data[index].GroupByHeader != dsItemsProperties.data[index-1].GroupByHeader;
        }else if(dsItemsProperties.data[index].GroupByHeader){
            return true;
        }else{
            return false;
        }
    }
    
    function showHeaderReadOnly(index){
        if(index > 0){
            return dsItemsProperties.data[index].GroupByHeader != dsItemsProperties.data[index-1].GroupByHeader && hasPropertyValues(dsItemsProperties.data[index].GroupByHeader);
        }else if(dsItemsProperties.data[index].GroupByHeader && hasPropertyValues(dsItemsProperties.data[index].GroupByHeader)){
            return true;
        }else{
            return false;
        }
    }

    function hasPropertyValues(pGroupByHeader){
        return dsItemsProperties.data.filter(x=> x.GroupByHeader == pGroupByHeader && (x.ShowAlways || x.Value != null || x.DateValue != null || x.IntValue != null || x.DateTimeValue != null)).length; 
    }

    function hasRequiredFields(){
        return dsItemsProperties.data.filter(x=> x && x.RequiredAtStep <= props.step).length > 0;
    }

    function sanitizeForId(inputString) {
        const sanitizedString = inputString.replace(/[^a-zA-Z0-9-_:.]/g, '');
        return sanitizedString;
    }

    function resetValue(item) {
        item.Value = item.oldValues?.Value ?? item.Value;
    }
</script>